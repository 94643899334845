import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

const SavedMails = ({ accessToken, loadMailForEdit, deleteMail }) => {
  const [savedMails, setSavedMails] = useState([]);

  useEffect(() => {
    if (accessToken) {
      fetchSavedMails();
    }
  }, [accessToken]);

  const fetchSavedMails = async () => {
    try {
      // Get all labels to find the 'Saved' label
      const labelsResponse = await axios.get('https://www.googleapis.com/gmail/v1/users/me/labels', {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      const labels = labelsResponse.data.labels;
      const savedLabel = labels.find(label => label.name === 'Saved');

      if (savedLabel) {
        const labelId = savedLabel.id;

        // Fetch messages with the 'Saved' label
        const messagesResponse = await axios.get('https://www.googleapis.com/gmail/v1/users/me/messages', {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: {
            labelIds: labelId,
            maxResults: 20,
          },
        });

        const messages = messagesResponse.data.messages || [];

        if (messages.length > 0) {
          const detailedMessages = await Promise.all(
            messages.map(async (message) => {
              const messageDetails = await axios.get(
                `https://www.googleapis.com/gmail/v1/users/me/messages/${message.id}`,
                {
                  headers: { Authorization: `Bearer ${accessToken}` },
                  params: { format: 'full' },
                }
              );
              return messageDetails.data;
            })
          );

          setSavedMails(detailedMessages);
        }
      } else {
        console.error('Saved label not found.');
      }
    } catch (error) {
      console.error('Error fetching saved emails:', error);
    }
  };

  const getMessageBody = (messagePayload) => {
    let encodedBody = '';

    if (!messagePayload.parts) {
      encodedBody = messagePayload.body.data;
    } else {
      encodedBody = getPlainTextPart(messagePayload.parts);
    }

    if (encodedBody) {
      const decodedBody = atob(encodedBody.replace(/-/g, '+').replace(/_/g, '/'));
      return decodedBody;
    } else {
      return '';
    }
  };

  const getPlainTextPart = (parts) => {
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].mimeType === 'text/plain') {
        return parts[i].body.data;
      } else if (parts[i].parts) {
        const textPart = getPlainTextPart(parts[i].parts);
        if (textPart) {
          return textPart;
        }
      }
    }
    return null;
  };

  return (
    <div className="container mt-5">
      <h1>Saved U-Mails</h1>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Subject</th>
            <th scope="col">Message</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {savedMails.map((mail, index) => {
            const subjectHeader = mail.payload.headers.find(header => header.name === 'Subject')?.value || 'No Subject';
            const messageBody = getMessageBody(mail.payload) || mail.snippet;

            return (
              <tr key={mail.id || index}>
                <td>{subjectHeader}</td>
                <td>{messageBody}</td>
                <td>
                  <button className="btn btn-info" onClick={() => loadMailForEdit(mail)}>
                    <FontAwesomeIcon icon={faPen} /> Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteMail(mail.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SavedMails;
