import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/home.css'; // Import your CSS file for additional styles
import { auth } from '../firebase-config'; // Ensure path matches your file structure
import { signOut } from 'firebase/auth';


function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [user, setUser] = useState(null); // Keep track of the authenticated user

  const navigate = useNavigate();

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      unsubscribe();
    };
  }, []);

  const handleLogout = async (event) => {
    event.preventDefault(); // Prevent the default link behavior
    try {
      setIsMenuOpen(false); // Close the menu when logging out
      await signOut(auth);
      navigate("/login");
      scrollToTop();
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <div className={`site-mobile-menu site-navbar-target ${isMenuOpen ? 'open' : ''}`}>
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle" onClick={toggleMenu}></span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>

      <header className={`site-navbar py-4 js-sticky-header site-navbar-target ${isScrolled ? 'scrolled' : ''}`} role="banner">
        <div className="container">
          <div className="align-items-center">
            {user ? (
              <Link to="/logged-in" className="text-black mb-0" onClick={scrollToTop}>
                <div className="col-11 col-xl-2">
                  <h1 className="mb-0 site-logo">
                    <img
                      src="/images/tum.png"
                      alt="Small plane"
                      style={{ width: '140px', height: '40%', float: 'left' }}
                    />
                  </h1>
                </div>
              </Link>
            ) : (
              <a href="https://u-mail.ai" className="text-black mb-0">
                <div className="col-11 col-xl-2">
                  <h1 className="mb-0 site-logo">
                    <img
                      src="/images/tum.png"
                      alt="Small plane"
                      style={{ width: '140px', height: '40%', float: 'left' }}
                    />
                  </h1>
                </div>
              </a>
            )}
            <div className="col-12 col-md-10 d-xl-block">
              <nav className="site-navigation position-relative text-right" role="navigation">
                <ul style={{  float:'right' }} className={`site-menu main-menu js-clone-nav mr-auto ${isMenuOpen ? 'show' : ''}`}>
                  {user ? (
                    <>
                      {/* Only show these links if the user is logged in */}
                      <li className="ml-3 ml-md-5">
                        <Link to="/logged-in" className="btn btn-dark" onClick={scrollToTop}>Inbox</Link>
                      </li>
                      <li className="ml-3 ml-md-5">
                        <Link to="/logged-in/faq" className="btn btn-dark" onClick={scrollToTop}>Help/FAQs</Link>
                      </li>
                      <li className="ml-3 ml-md-5">
                        <Link to="/settings" className="btn btn-dark" onClick={scrollToTop}>Settings</Link>
                      </li>
                      <li className="ml-3 ml-md-5">
                        <Link href="/login" onClick={handleLogout} className="btn btn-dark" passHref>Log out</Link>
                      </li>
                    </>
                  ) : (
                    <li className="ml-3 ml-md-5">
                      <Link to="/login" className="nav-link" onClick={scrollToTop}>Login</Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
            <div className="d-inline-block d-xl-none ml-md-0 mr-auto py-3" style={{ position: 'relative', top: '3px' }}>
              <span className="icon-menu h3" onClick={toggleMenu}></span>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
