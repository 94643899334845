import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply, faForward, faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const Inbox = ({ triggerPopupMessage, handleMailClick, setActiveTab, accessToken, userEmail, setMailContent }) => {
  const [inboxEmails, setInboxEmails] = useState([]);

  useEffect(() => {
    if (accessToken) {
      fetchInboxEmails(accessToken);  // Fetch emails once the token is available
    }
  }, [accessToken]);

  const handleGoogleSignIn = () => {
    const redirectUri = process.env.NODE_ENV === 'development'
      ? 'https://login.u-mail.ai/logged-in'  // Development URI
      : 'https://login.u-mail.ai/logged-in';  // Production URI
  
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://mail.google.com/&response_type=token&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${redirectUri}&access_type=offline&prompt=consent`;
    
    window.location.href = authUrl;
  };
  

  const fetchInboxEmails = async (token) => {
    try {
      const response = await axios.get('https://www.googleapis.com/gmail/v1/users/me/messages', {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          maxResults: 10,  // Fetch the last 10 emails
          q: 'in:inbox',   // Filter for inbox emails
        },
      });
  
      const messages = response.data.messages || [];
  
      if (messages.length === 0) {
        console.log('No emails found.');
        triggerPopupMessage('No emails found.');
      } else {
        const detailedMessages = await Promise.all(
          messages.map(async (message) => {
            const messageDetails = await axios.get(
              `https://www.googleapis.com/gmail/v1/users/me/messages/${message.id}`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            return messageDetails.data;
          })
        );
        
        console.log('Detailed emails:', detailedMessages);
        setInboxEmails(detailedMessages); // Set inbox emails with full details
  
        detailedMessages.forEach((email) => {
          console.log("Email Subject:", email.payload.headers.find(header => header.name === 'Subject')?.value || 'No Subject');
          console.log("Email Snippet:", email.snippet);
          console.log("Full Email Data:", email);
        });
      }
    } catch (error) {
      console.error('Failed to fetch inbox emails:', error);
      triggerPopupMessage('Failed to fetch inbox emails.');
    }
  };

  const handleReply = (email) => {
    const from = email.payload.headers.find(header => header.name === 'From')?.value || '';
    const to = email.payload.headers.find(header => header.name === 'To')?.value || '';
    const subject = `Re: ${email.payload.headers.find(header => header.name === 'Subject')?.value || ''}`;
  
    // Extracting the full email body (text/plain)
    const messagePart = email.payload.parts?.find(part => part.mimeType === 'text/plain');
    const message = messagePart ? atob(messagePart.body.data.replace(/-/g, '+').replace(/_/g, '/')) : email.snippet;  // Base64 decoding

    setMailContent({
      id: email.id,
      from: from,
      to: to,
      subject: subject,
      message: message  // Decoded full body or snippet as fallback
    });
    setActiveTab('new-u-mail');
  };

  const handleForward = (email) => {
    const from = email.payload.headers.find(header => header.name === 'From')?.value || '';
    const subject = `Fwd: ${email.payload.headers.find(header => header.name === 'Subject')?.value || ''}`;
  
    // Extracting the full email body (text/plain)
    const messagePart = email.payload.parts?.find(part => part.mimeType === 'text/plain');
    const message = messagePart ? atob(messagePart.body.data.replace(/-/g, '+').replace(/_/g, '/')) : email.snippet;  // Base64 decoding

    setMailContent({
      id: email.id,
      from: from,
      to: '',  // Empty for forwarding
      subject: subject,
      message: message  // Decoded full body or snippet as fallback
    });
    setActiveTab('new-u-mail');
  };

  const handleDelete = (e, emailId) => {
    e.stopPropagation();
    // Implement delete functionality if needed
  };

  return (
    <div className="container mt-5">
      <h1>Received U-Mails</h1>

      <button className="btn btn-primary" onClick={handleGoogleSignIn}>
        Sign in with Google
      </button>

      <table className="table table-hover mt-3">
        <thead>
          <tr>
            <th scope="col">From</th>
            <th scope="col">Subject</th>
            <th scope="col">Snippet</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {inboxEmails.length > 0 ? (
            inboxEmails.map((email, index) => {
              const fromHeader = email.payload.headers.find(header => header.name === 'From')?.value || 'Unknown';
              const subjectHeader = email.payload.headers.find(header => header.name === 'Subject')?.value || 'No Subject';
              const truncatedSnippet = email.snippet.length > 50 ? `${email.snippet.slice(0, 50)}...` : email.snippet;

              return (
                <tr key={email.id || index} onClick={() => handleMailClick(email)}>
                  <td>{fromHeader}</td>
                  <td>{subjectHeader}</td>
                  <td>{truncatedSnippet}</td>
                  <td style={{ display: 'flex' }}>
                    <button className="btn btn-light mr-2" onClick={() => handleReply(email)}>
                      <FontAwesomeIcon icon={faReply} />
                    </button>
                    <button className="btn btn-light mr-2" onClick={() => handleForward(email)}>
                      <FontAwesomeIcon icon={faForward} />
                    </button>
                    <button className="btn btn-light" onClick={(e) => handleDelete(e, email.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4" className="text-center">No emails found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Inbox;
