import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase-config";
import { collection, getDocs, query, where } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faPlus,
  faQuestionCircle,
  faCog,
  faBriefcase,
  faReply,
  faForward,
  faTrash,
  faStar,
  faExclamation,
  faEllipsisVertical,
  faPrint,
  faBan,
  faExclamationTriangle,
  faFish,
  faArrowsRotate,
  faInbox,
  faTag,
  faArchive,
  faUserFriends,
  faShoppingBag,
  faBell,
  faChevronDown,
  faChevronRight,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import NewMail from "../scripts/newUmAIl";
import FAQ from "./loggedIn/FAQ";
import EditDetails from "./loggedIn/EditDetails";
import Settings from "./Settings";
import SavedMails from "./loggedIn/SavedMails";
import SentMails from "./loggedIn/SentMails";
import VoiceToText from "./VoiceToText";
import JobHelp from "./loggedIn/JobHelp";
import axiosInstance from "../axiosInstance";
import { Routes, Route } from 'react-router-dom';
import '../styles/mobile.css'; // Import the mobile CSS

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const LoggedIn = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [savedMails, setSavedMails] = useState([]);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [mailContent, setMailContent] = useState({
    id: null,
    from: "",
    to: "",
    subject: "",
    message: "",
  });
  const [activeTab, setActiveTab] = useState("received-mails");
  const [popupMessage, setPopupMessage] = useState("");
  const [selectedMail, setSelectedMail] = useState(null);
  const [signature, setSignature] = useState("");
  const [useSignature, setUseSignature] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [accessToken, setAccessToken] = useState(
    sessionStorage.getItem("accessToken") || null
  );
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("primary");
  const [sentEmails, setSentEmails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const emailsPerPage = 20;
  const [isListening, setIsListening] = useState(false);
  const [countdownText, setCountdownText] = useState("Ready");
  const [inboxEmails, setInboxEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [activeLabel, setActiveLabel] = useState(null);
  const [labelsOpen, setLabelsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false); // For sidebar toggle

  const labelsToExclude = ["CATEGORY_PRIMARY", "CATEGORY_PROMOTIONS", "CATEGORY_SOCIAL", "INBOX", "CHAT", "CATEGORY_FORUMS", "CATEGORY_UPDATES", "CATEGORY_PERSONAL", "[Imap]/Drafts", "[Imap]/Outbox" ];

  const triggerPopupMessage = (message) => {
    setPopupMessage(message);
    setTimeout(() => setPopupMessage(""), 4000);
  };

  // Cache for email details to prevent redundant API calls
  const emailCache = useRef({});

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) navigate("/login");
      else {
        setUserEmail(user.email);
        fetchSavedMails();
      }
    });

    const fetchGmailData = async (token) => {
      try {
        await fetchInboxEmails(token);
        await delay(1000); // Be cautious with delays to prevent rate limits
        await fetchGmailLabels(token);
        await delay(1000);
        await fetchSentEmails(token);
      } catch (error) {
        console.error("Error fetching Gmail data:", error);
      }
    };

    const token = sessionStorage.getItem("accessToken");
    if (token) {
      setAccessToken(token);
      fetchGmailData(token);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get("code");

    if (authorizationCode) {
      exchangeAuthorizationCode(authorizationCode);
    } else if (sessionStorage.getItem("accessToken")) {
      const storedTime = sessionStorage.getItem("tokenTime");
      if (new Date().getTime() - storedTime > 3600000) {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("tokenTime");
        setAccessToken(null);
      }
    }

    if (token) {
      fetchGmailData(token);
    }

    return () => unsubscribe();
  }, [navigate]);

  const exchangeAuthorizationCode = async (code) => {
    try {
      const response = await axiosInstance.post("api/exchange-code", { code });
      const { access_token } = response.data;

      sessionStorage.setItem("accessToken", access_token);
      sessionStorage.setItem("tokenTime", new Date().getTime());
      setAccessToken(access_token);

      fetchInboxEmails(access_token);
      fetchSentEmails(access_token);
    } catch (error) {
      console.error("Error exchanging authorization code:", error);
    }
  };

  const fetchInboxEmails = async (token) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        "https://www.googleapis.com/gmail/v1/users/me/messages",
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { maxResults: 75, q: "in:inbox" },
        }
      );

      const messages = response.data.messages || [];
      const detailedMessages = await Promise.all(
        messages.map(async (message) => {
          const messageDetails = await axiosInstance.get(
            `https://www.googleapis.com/gmail/v1/users/me/messages/${message.id}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          return messageDetails.data;
        })
      );

      const primaryEmails = detailedMessages.filter(
        (email) =>
          email.labelIds?.includes("CATEGORY_PRIMARY") ||
          (!email.labelIds?.includes("CATEGORY_PROMOTIONS") &&
            !email.labelIds?.includes("CATEGORY_SOCIAL"))
      );

      const socialEmails = detailedMessages.filter((email) =>
        email.labelIds?.includes("CATEGORY_SOCIAL")
      );

      const promotionsEmails = detailedMessages.filter((email) =>
        email.labelIds?.includes("CATEGORY_PROMOTIONS")
      );

      setInboxEmails([
        ...primaryEmails,
        ...socialEmails,
        ...promotionsEmails,
      ]);
      setFilteredEmails([
        ...primaryEmails,
        ...socialEmails,
        ...promotionsEmails,
      ]);

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch inbox emails:", error);
      setLoading(false);
      triggerPopupMessage("Failed to fetch inbox emails.");
    }
  };

  const fetchSentEmails = async (token) => {
    try {
      const response = await axiosInstance.get(
        "https://www.googleapis.com/gmail/v1/users/me/messages",
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            q: "in:sent",
            maxResults: 100,
          },
        }
      );
      const messages = response.data.messages || [];
      setSentEmails(messages);
    } catch (error) {
      console.error("Failed to fetch sent emails:", error);
      triggerPopupMessage("Failed to fetch sent emails.");
    }
  };

  const fetchSavedMails = async () => {
    if (!auth.currentUser) return;

    try {
      const q = query(
        collection(db, "savedMails"),
        where("userId", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const mails = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSavedMails(querySnapshot.empty ? [] : mails);
    } catch (error) {
      console.error("Error fetching saved mails:", error);
      triggerPopupMessage("Failed to fetch saved mails.");
    }
  };

  const handleMailClick = async (emailId) => {
    if (selectedMail && selectedMail.id === emailId) {
      setSelectedMail(null);
    } else {
      const selectedEmail = filteredEmails.find((email) => email.id === emailId);

      // Safely check if labelIds exists and includes 'UNREAD'
      if (selectedEmail.labelIds?.includes("UNREAD")) {
        try {
          await axiosInstance.post(
            `https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`,
            {
              removeLabelIds: ["UNREAD"],
            },
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );

          // Update labelIds locally
          const updatedInboxEmails = inboxEmails.map((email) =>
            email.id === emailId
              ? { ...email, labelIds: email.labelIds.filter((id) => id !== "UNREAD") }
              : email
          );
          setInboxEmails(updatedInboxEmails);
          setFilteredEmails(updatedInboxEmails);
        } catch (error) {
          console.error("Failed to mark email as read:", error);
          triggerPopupMessage("Failed to mark email as read.");
          return; // Exit the function to prevent further execution
        }
      }

      // Check if email details are cached
      if (emailCache.current[emailId]) {
        setSelectedMail(emailCache.current[emailId]);
      } else {
        try {
          const response = await axiosInstance.get(`/api/gmail/messages/${emailId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const emailData = response.data;

          // Recursive function to find the body part with 'text/html' or 'text/plain' mime type
          const findBodyPart = (parts) => {
            for (const part of parts) {
              if (part.mimeType === "text/html" || part.mimeType === "text/plain") {
                return part;
              }
              if (part.parts) {
                const result = findBodyPart(part.parts);
                if (result) return result;
              }
            }
            return null;
          };

          // Recursive function to collect all attachment parts
          const collectAttachments = (parts, attachmentsList = []) => {
            for (const part of parts) {
              if (part.filename && part.body && part.body.attachmentId) {
                attachmentsList.push(part);
              }
              if (part.parts) {
                collectAttachments(part.parts, attachmentsList);
              }
            }
            return attachmentsList;
          };

          let decodedMessage = "";
          let isHtml = false;

          // Collect all attachments
          const attachments = {};
          const attachmentParts = collectAttachments(emailData.payload.parts || []);

          // Fetch attachments data
          if (attachmentParts.length > 0) {
            await Promise.all(
              attachmentParts.map(async (part) => {
                const attachmentResponse = await axiosInstance.get(
                  `/api/gmail/messages/${emailId}/attachments/${part.body.attachmentId}`,
                  {
                    headers: { Authorization: `Bearer ${accessToken}` },
                  }
                );
                attachments[part.body.attachmentId] = attachmentResponse.data.data;
              })
            );
          }

          // Check if the email body is directly available
          if (emailData.payload.body?.data) {
            decodedMessage = decodeBase64(emailData.payload.body.data);
            isHtml = emailData.payload.mimeType === "text/html";
          } else {
            // Use the recursive function to find the body part
            const bodyPart = findBodyPart(emailData.payload.parts || []);
            if (bodyPart) {
              decodedMessage = decodeBase64(bodyPart.body.data || "");
              isHtml = bodyPart.mimeType === "text/html";
            } else {
              decodedMessage = "No content available.";
            }
          }

          // Replace 'cid:' URLs with actual attachment data if it's an HTML email
          if (isHtml) {
            decodedMessage = decodedMessage.replace(/cid:([^'">]+)/g, (match, cid) => {
              const attachment = attachmentParts?.find((part) =>
                part.headers?.some(
                  (header) => header.name === "Content-ID" && header.value.includes(cid)
                )
              );
              if (attachment && attachments[attachment.body.attachmentId]) {
                const attachmentData = attachments[attachment.body.attachmentId];
                return `data:${attachment.mimeType};base64,${attachmentData}`;
              }
              return match;
            });
          }

          const emailDetail = {
            ...emailData,
            decodedMessage,
            isHtml,
          };

          // Cache the email details
          emailCache.current[emailId] = emailDetail;
          setSelectedMail(emailDetail);
        } catch (error) {
          console.error("Failed to fetch email details:", error);
          triggerPopupMessage("Failed to fetch email details.");
        }
      }
    }
  };

  const handleClosePopup = () => {
    setSelectedMail(null);
  };

  const loadMailForEdit = (mail) => {
    setMailContent({
      id: mail.id,
      from: userEmail,
      to: mail.to,
      subject: mail.subject,
      message: mail.message,
    });
    setActiveTab("new-u-mail");
  };

  const handleGoogleSignIn = () => {
    const redirectUri = "https://login.u-mail.ai/logged-in";
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://mail.google.com/&response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&access_type=offline&prompt=consent`;

    window.location.href = authUrl;
  };

  const handleReply = (email) => {
    const from =
      email.payload.headers.find((header) => header.name === "From")?.value ||
      "";
    const subject = `Re: ${
      email.payload.headers.find((header) => header.name === "Subject")?.value ||
      ""
    }`;
    const messagePart = email.payload.parts?.find(
      (part) => part.mimeType === "text/plain"
    );
    const message = messagePart
      ? decodeBase64(messagePart.body.data)
      : email.snippet;

    setMailContent({
      from: userEmail,
      to: from,
      subject: subject,
      message: `\n\n--- Original Message ---\n${message}`,
    });
    setActiveTab("new-u-mail");
  };

  const handleForward = (email) => {
    const subject = `Fwd: ${
      email.payload.headers.find((header) => header.name === "Subject")?.value ||
      ""
    }`;
    const messagePart = email.payload.parts?.find(
      (part) => part.mimeType === "text/plain"
    );
    const message = messagePart
      ? decodeBase64(messagePart.body.data)
      : email.snippet;

    setMailContent({
      from: userEmail,
      to: "",
      subject: subject,
      message: `\n\n--- Forwarded Message ---\n${message}`,
    });
    setActiveTab("new-u-mail");
  };

  const handleDelete = async (emailId) => {
    try {
      await axiosInstance.delete(
        `https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const updatedInboxEmails = inboxEmails.filter(
        (email) => email.id !== emailId
      );
      setInboxEmails(updatedInboxEmails);
      setFilteredEmails(updatedInboxEmails);

      if (selectedMail && selectedMail.id === emailId) {
        setSelectedMail(null);
      }

      triggerPopupMessage("Email deleted successfully.");
    } catch (error) {
      console.error("Failed to delete email:", error);
      triggerPopupMessage("Failed to delete email.");
    }
  };

  const indexOfLastEmail = currentPage * emailsPerPage;
  const indexOfFirstEmail = indexOfLastEmail - emailsPerPage;
  const currentEmails = filteredEmails.slice(
    indexOfFirstEmail,
    indexOfLastEmail
  );

  const paginateNext = () => {
    if (currentPage < Math.ceil(filteredEmails.length / emailsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const paginatePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleFilterChange = (filter) => {
    if (!Array.isArray(inboxEmails)) {
      console.error("inboxEmails is not an array");
      return;
    }

    let filtered = [];

    if (filter === "primary") {
      filtered = inboxEmails.filter(
        (email) =>
          email.labelIds?.includes("CATEGORY_PRIMARY") ||
          (!email.labelIds?.includes("CATEGORY_PROMOTIONS") &&
            !email.labelIds?.includes("CATEGORY_SOCIAL"))
      );
    } else if (filter === "promotions") {
      filtered = inboxEmails.filter((email) =>
        email.labelIds?.includes("CATEGORY_PROMOTIONS")
      );
    } else if (filter === "social") {
      filtered = inboxEmails.filter((email) =>
        email.labelIds?.includes("CATEGORY_SOCIAL")
      );
    } else if (filter === "updates") {
      filtered = inboxEmails.filter((email) =>
        email.labelIds?.includes("CATEGORY_UPDATES")
      );
    }

    setFilteredEmails(filtered);
    setSelectedFilter(filter);
    setCurrentPage(1);
    setActiveTab("received-mails");
    setActiveLabel(null);
  };

  const decodeBase64 = (encodedData) => {
    try {
      const fixedEncodedData = encodedData
        .replace(/-/g, "+")
        .replace(/_/g, "/")
        .padEnd(encodedData.length + (encodedData.length % 4), "=");
      return decodeURIComponent(escape(window.atob(fixedEncodedData)));
    } catch (error) {
      console.error("Error decoding base64:", error);
      return "Error decoding message.";
    }
  };

  const handleStarClick = async (e, emailId) => {
    e.stopPropagation();

    try {
      const email = inboxEmails.find((email) => email.id === emailId);
      const isStarred = email.labelIds?.includes("STARRED");

      if (isStarred) {
        await axiosInstance.post(
          `https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`,
          {
            removeLabelIds: ["STARRED"],
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
      } else {
        await axiosInstance.post(
          `https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`,
          {
            addLabelIds: ["STARRED"],
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
      }

      // Update labelIds locally
      const updatedInboxEmails = inboxEmails.map((email) =>
        email.id === emailId
          ? isStarred
            ? { ...email, labelIds: email.labelIds.filter(id => id !== "STARRED") }
            : { ...email, labelIds: [...email.labelIds, "STARRED"] }
          : email
      );
      setInboxEmails(updatedInboxEmails);
      setFilteredEmails(updatedInboxEmails);
    } catch (error) {
      console.error("Failed to toggle star:", error);
      triggerPopupMessage("Failed to toggle star.");
    }
  };

  const handleImportantClick = async (e, emailId) => {
    e.stopPropagation();

    try {
      const email = inboxEmails.find((email) => email.id === emailId);
      const isImportant = email.labelIds?.includes("IMPORTANT");

      if (isImportant) {
        await axiosInstance.post(
          `https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`,
          {
            removeLabelIds: ["IMPORTANT"],
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
      } else {
        await axiosInstance.post(
          `https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`,
          {
            addLabelIds: ["IMPORTANT"],
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
      }

      // Update labelIds locally
      const updatedInboxEmails = inboxEmails.map((email) =>
        email.id === emailId
          ? isImportant
            ? { ...email, labelIds: email.labelIds.filter(id => id !== "IMPORTANT") }
            : { ...email, labelIds: [...email.labelIds, "IMPORTANT"] }
          : email
      );
      setInboxEmails(updatedInboxEmails);
      setFilteredEmails(updatedInboxEmails);
    } catch (error) {
      console.error("Failed to toggle important marker:", error);
      triggerPopupMessage("Failed to toggle important marker.");
    }
  };

  const handleCheckboxClick = (e, emailId) => {
    e.stopPropagation();
    if (selectedEmails.includes(emailId)) {
      setSelectedEmails(selectedEmails.filter((id) => id !== emailId));
    } else {
      setSelectedEmails([...selectedEmails, emailId]);
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedEmails([]);
    } else {
      const allEmailIds = currentEmails.map((email) => email.id);
      setSelectedEmails(allEmailIds);
    }
    setAllSelected(!allSelected);
  };

  const toggleOptionsMenu = () => {
    setShowOptionsMenu(!showOptionsMenu);
  };

  const fetchGmailLabels = async (token) => {
    try {
      const response = await axiosInstance.get(
        "https://www.googleapis.com/gmail/v1/users/me/labels",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLabels(response.data.labels || []);
    } catch (error) {
      console.error("Failed to fetch Gmail labels:", error);
      triggerPopupMessage("Failed to fetch Gmail labels.");
    }
  };

  useEffect(() => {
    if (accessToken) {
      fetchGmailLabels(accessToken);
    }
  }, [accessToken]);

  const handleAddToLabel = async (emailId) => {
    if (!selectedLabel) {
      alert("Please select a label.");
      return;
    }

    try {
      await axiosInstance.post(
        `https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`,
        { addLabelIds: [selectedLabel] },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      alert("Label added successfully!");

      // Update labelIds locally
      const updatedInboxEmails = inboxEmails.map((email) =>
        email.id === emailId
          ? { ...email, labelIds: [...email.labelIds, selectedLabel] }
          : email
      );
      setInboxEmails(updatedInboxEmails);
      setFilteredEmails(updatedInboxEmails);
    } catch (error) {
      console.error("Failed to add label:", error);
      alert("Failed to add label.");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e, labelId) => {
    e.preventDefault();
    e.stopPropagation();
    const emailId = e.dataTransfer.getData("emailId");

    try {
      await axiosInstance.post(
        `https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`,
        { addLabelIds: [labelId] },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      alert("Email added to label!");

      // Update labelIds locally
      const updatedInboxEmails = inboxEmails.map((email) =>
        email.id === emailId
          ? { ...email, labelIds: [...email.labelIds, labelId] }
          : email
      );
      setInboxEmails(updatedInboxEmails);
      setFilteredEmails(updatedInboxEmails);
    } catch (error) {
      console.error("Error adding label:", error);
      alert("Error adding label.");
    }
  };

  const handleDragStart = (e, emailId) => {
    e.dataTransfer.setData("emailId", emailId);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleBlockSender = async () => {
    const senderEmail = selectedMail.payload.headers.find(
      (header) => header.name === "From"
    )?.value;

    alert(`Sender ${senderEmail} blocked.`);
    // Implement actual blocking logic if required
  };

  const handleReportSpam = async () => {
    try {
      await axiosInstance.post(
        `https://www.googleapis.com/gmail/v1/users/me/messages/${selectedMail.id}/modify`,
        { addLabelIds: ["SPAM"] },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      await axiosInstance.delete(
        `https://www.googleapis.com/gmail/v1/users/me/messages/${selectedMail.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      setPopupMessage("Reported as spam and deleted.");
      setTimeout(() => {
        setPopupMessage("");
      }, 3000);

      const updatedInboxEmails = inboxEmails.filter((email) => email.id !== selectedMail.id);
      setInboxEmails(updatedInboxEmails);
      setFilteredEmails(updatedInboxEmails);
      setSelectedMail(null);
    } catch (error) {
      console.error("Failed to report spam and delete the email:", error);
      alert("Failed to report spam.");
    }
  };

  const handleReportPhishing = async () => {
    try {
      await axiosInstance.post(
        `https://www.googleapis.com/gmail/v1/users/me/messages/${selectedMail.id}/modify`,
        { addLabelIds: ["PHISHING"] },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      alert("Reported as phishing.");
    } catch (error) {
      console.error("Failed to report phishing:", error);
      alert("Failed to report phishing.");
    }
  };

  const handleRefreshInbox = async () => {
    setLoading(true);
    try {
      await fetchInboxEmails(accessToken);
      setPopupMessage("Inbox refreshed.");
      setTimeout(() => setPopupMessage(""), 3000);
      setLoading(false);
    } catch (error) {
      console.error("Failed to refresh inbox:", error);
      setPopupMessage("Failed to refresh inbox.");
      setTimeout(() => setPopupMessage(""), 3000);
      setLoading(false);
    }
  };

  // Handle label clicks
  const handleLabelClick = async (labelId, labelName) => {
    setActiveTab("label");
    setActiveLabel({ id: labelId, name: labelName });
    setLoading(true);
    await fetchEmailsByLabel(labelId);
    setLoading(false);
  };

  // Fetch emails by label
  const fetchEmailsByLabel = async (labelId) => {
    try {
      const response = await axiosInstance.get(
        "https://www.googleapis.com/gmail/v1/users/me/messages",
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: {
            maxResults: 75,
            labelIds: labelId,
          },
        }
      );
      const messages = response.data.messages || [];
      const detailedMessages = await Promise.all(
        messages.map(async (message) => {
          const messageDetails = await axiosInstance.get(
            `https://www.googleapis.com/gmail/v1/users/me/messages/${message.id}`,
            { headers: { Authorization: `Bearer ${accessToken}` } }
          );
          return messageDetails.data;
        })
      );
      setFilteredEmails(detailedMessages);
      setCurrentPage(1);
    } catch (error) {
      console.error("Failed to fetch emails by label:", error);
      triggerPopupMessage("Failed to fetch emails by label.");
    }
  };

  // Toggle labels open/closed
  const toggleLabels = () => {
    setLabelsOpen(!labelsOpen);
  };

  // New function to handle email sent and redirect to inbox
  const handleEmailSent = () => {
    setActiveTab("received-mails");
    // Refresh inbox emails
    fetchInboxEmails(accessToken);
  };

  return (
    <>
      {loading && (
        <div className="spinner">
          <FontAwesomeIcon icon="spinner" spin />
        </div>
      )}

      <div className="gmail-container">
      <div className={`gmail-sidebar ${sidebarOpen ? 'open' : 'closed'}`}>
      <ul className="sidebar-menu">  
        
        <div className="compose-button" onClick={() => setActiveTab("new-u-mail")}>
        <li
      className={` ${
        activeTab === "received-mails" && !activeLabel ? "active" : ""
      }`}
      onClick={() => {
        setActiveTab("received-mails");
        setActiveLabel(null);
        setFilteredEmails(inboxEmails);
        setSidebarOpen(false); // Close sidebar on mobile after selection
      }}
    >
      <FontAwesomeIcon icon={faInbox} />
           <FontAwesomeIcon icon={faPlus} />
           <span>Compose</span>
            </li>
      </div>
      

    <li
      className={`sidebar-item ${
        activeTab === "received-mails" && !activeLabel ? "active" : ""
      }`}
      onClick={() => {
        setActiveTab("received-mails");
        setActiveLabel(null);
        setFilteredEmails(inboxEmails);
        setSidebarOpen(false); // Close sidebar on mobile after selection
      }}
    >
      <FontAwesomeIcon icon={faInbox} />
      <span>Inbox</span>
    </li>

    {/* Labels Section */}
    <div className="sidebar-labels mt-4">
      <ul className="labels-list">
        {labels
          .filter((label) => !labelsToExclude.includes(label.name))
          .map((label) => (
            <li
              key={label.id}
              className={`sidebar-item ${
                activeLabel?.id === label.id ? "active" : ""
              }`}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, label.id)}
              onClick={() => {
                handleLabelClick(label.id, label.name);
                setSidebarOpen(false); // Close sidebar on mobile after selection
              }}
            >
              <FontAwesomeIcon icon={faTag} />
              <span className="ml-2">{label.name}</span>
            </li>
          ))}
      </ul>
    </div>
  </ul>
</div>


        <div className="gmail-content">
          <button className="sidebar-toggle" onClick={() => setSidebarOpen(!sidebarOpen)}>
            <FontAwesomeIcon icon={faBars} />
          </button>

          {activeTab === "new-u-mail" ? (
            <div className="compose-mail">
              <NewMail
                isListening={isListening}
                setCountdownText={setCountdownText}
                handleFetchedMails={setSavedMails}
                setPopupMessage={triggerPopupMessage}
                useSignature={useSignature}
                setUseSignature={setUseSignature}
                signature={signature}
                setSignature={setSignature}
                mailContent={mailContent}
                setMailContent={setMailContent}
                accessToken={accessToken}
                userEmail={userEmail}
                onEmailSent={handleEmailSent} // Passed the handleEmailSent function
              />
              <VoiceToText
                isListening={isListening}
                setIsListening={setIsListening}
                countdownText={countdownText}
                setCountdownText={setCountdownText}
              />
            </div>
          ) : activeTab === "saved-u-mails" ? (
            <SavedMails savedMails={savedMails} loadMailForEdit={loadMailForEdit} />
          ) : activeTab === "sent-mails" ? (
            <SentMails
              accessToken={accessToken}
              sentMails={sentEmails}
              handleMailClick={handleMailClick}
              selectedMail={selectedMail}
              handleClosePopup={handleClosePopup}
            />
          ) : activeTab === "received-mails" || activeTab === "label" ? (
            <div className="inbox-container">
              <div className="inbox-header">
                {activeTab === "received-mails" && (
                  <div className="inbox-filters">
                    <button
                      className={`filter-button ${
                        selectedFilter === "primary" ? "active" : ""
                      }`}
                      onClick={() => handleFilterChange("primary")}
                    >
                      <FontAwesomeIcon icon={faInbox} />
                      <span>Primary</span>
                    </button>
                    <button
                      className={`filter-button ${
                        selectedFilter === "social" ? "active" : ""
                      }`}
                      onClick={() => handleFilterChange("social")}
                    >
                      <FontAwesomeIcon icon={faUserFriends} />
                      <span>Social</span>
                    </button>
                    <button
                      className={`filter-button ${
                        selectedFilter === "promotions" ? "active" : ""
                      }`}
                      onClick={() => handleFilterChange("promotions")}
                    >
                      <FontAwesomeIcon icon={faShoppingBag} />
                      <span>Promotions</span>
                    </button>
                    <button
                      className={`filter-button ${
                        selectedFilter === "updates" ? "active" : ""
                      }`}
                      onClick={() => handleFilterChange("updates")}
                    >
                      <FontAwesomeIcon icon={faBell} />
                      <span>Updates</span>
                    </button>
                  </div>
                )}
                {activeTab === "label" && (
                  <h2>{activeLabel?.name || "Label"}</h2>
                )}
                <div className="inbox-actions">
                  <button
                    className="action-button"
                    onClick={handleSelectAll}
                    title="Select All"
                  >
                    <input
                      type="checkbox"
                      checked={allSelected}
                      onChange={handleSelectAll}
                    />
                  </button>
                  <button
                    className="action-button"
                    onClick={handleRefreshInbox}
                    title="Refresh"
                  >
                    <FontAwesomeIcon icon={faArrowsRotate} />
                  </button>
                </div>
              </div>

              <div className="email-list">
                {currentEmails.length > 0 ? (
                  currentEmails.map((email, index) => {
                    const fromHeader =
                      email?.payload?.headers?.find(
                        (header) => header.name === "From"
                      )?.value || "Unknown";
                    const subjectHeader =
                      email?.payload?.headers?.find(
                        (header) => header.name === "Subject"
                      )?.value || "No Subject";
                    const dateHeader =
                      email?.payload?.headers?.find(
                        (header) => header.name === "Date"
                      )?.value || "Unknown Date";

                    const emailDate = new Date(dateHeader);
                    const today = new Date();
                    const isToday =
                      emailDate.toDateString() === today.toDateString();
                    const formattedDate = isToday
                      ? emailDate.toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : emailDate.toLocaleDateString();

                    const isStarred = email.labelIds?.includes("STARRED") || false;
                    const isImportant = email.labelIds?.includes("IMPORTANT") || false;
                    const isUnread = email.labelIds?.includes("UNREAD") || false;
                    const isChecked = selectedEmails.includes(email.id);
                    const isEmailSelected =
                      selectedMail && selectedMail.id === email.id;

                    return (
                      <React.Fragment key={email.id || index}>
                        <div
                          className={`email-item ${
                            isUnread ? "unread-email" : ""
                          } ${isEmailSelected ? "selected" : ""}`}
                          onClick={() => handleMailClick(email.id)}
                          draggable="true"
                          onDragStart={(e) => handleDragStart(e, email.id)}
                        >
                          <div className="email-checkbox">
                            <input
                              type="checkbox"
                              checked={isChecked}
                              onChange={(e) => handleCheckboxClick(e, email.id)}
                            />
                          </div>
                          <div
                            className="email-star"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleStarClick(e, email.id);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faStar}
                              className={isStarred ? "text-warning" : ""}
                            />
                          </div>
                          <div
                            className="email-important"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleImportantClick(e, email.id);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faExclamation}
                              className={isImportant ? "text-danger" : ""}
                            />
                          </div>
                          <div className="email-sender">{fromHeader}</div>
                          <div className="email-subject">{subjectHeader}</div>
                          <div className="email-date">{formattedDate}</div>
                        </div>

                        {isEmailSelected && (
                          <div className="email-content-area">
                            <div className="email-content-header">
                              <h2>{subjectHeader}</h2>
                              <div className="email-content-actions">
                                <button
                                  className="btn"
                                  onClick={() => handleReply(selectedMail)}
                                >
                                  <FontAwesomeIcon icon={faReply} /> Reply
                                </button>
                                <button
                                  className="btn"
                                  onClick={() => handleForward(selectedMail)}
                                >
                                  <FontAwesomeIcon icon={faForward} /> Forward
                                </button>
                                <button
                                  className="btn"
                                  onClick={() => handleDelete(selectedMail.id)}
                                >
                                  <FontAwesomeIcon icon={faTrash} /> Delete
                                </button>
                                <div className="email-settings-dropdown">
                                  <FontAwesomeIcon
                                    icon={faEllipsisVertical}
                                    className="settings-icon"
                                    onClick={toggleOptionsMenu}
                                  />
                                  {showOptionsMenu && (
                                    <div className="settings-menu">
                                      <button
                                        className="dropdown-item"
                                        onClick={handlePrint}
                                      >
                                        <FontAwesomeIcon icon={faPrint} /> Print
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        onClick={handleBlockSender}
                                      >
                                        <FontAwesomeIcon icon={faBan} /> Block
                                        Sender
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        onClick={handleReportSpam}
                                      >
                                        <FontAwesomeIcon
                                          icon={faExclamationTriangle}
                                        />{" "}
                                        Report Spam
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        onClick={handleReportPhishing}
                                      >
                                        <FontAwesomeIcon icon={faFish} /> Report
                                        Phishing
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="email-content-body">
                              {selectedMail.isHtml ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: selectedMail.decodedMessage,
                                  }}
                                />
                              ) : (
                                <pre>{selectedMail.decodedMessage}</pre>
                              )}
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div className="no-emails">No emails found.</div>
                )}
              </div>

              <div className="pagination-controls">
                <button
                  onClick={paginatePrev}
                  disabled={currentPage === 1}
                  className="btn"
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of{" "}
                  {Math.ceil(filteredEmails.length / emailsPerPage)}
                </span>
                <button
                  onClick={paginateNext}
                  disabled={
                    currentPage ===
                    Math.ceil(filteredEmails.length / emailsPerPage)
                  }
                  className="btn"
                >
                  Next
                </button>
              </div>

              {!accessToken && (
                <button
                  className="btn google-signin"
                  onClick={handleGoogleSignIn}
                >
                  Sign in with Google
                </button>
              )}
            </div>
          ) : activeTab === "help-faqs" ? (
            <FAQ />
          ) : activeTab === "job-help" ? (
            <JobHelp />
          ) : activeTab === "settings" ? (
            <Settings
              triggerPopupMessage={triggerPopupMessage}
              setActiveTab={setActiveTab}
              setIsEditing={setIsEditing}
            />
          ) : null}
        </div>
      </div>
      <div>
        {/* Other components like Header */}
        <Routes>
          <Route path="faq" element={<FAQ />} />
          {/* Other sub-routes */}
        </Routes>
      </div>

      {popupMessage && (
        <div className="popup">
          <p>{popupMessage}</p>
        </div>
      )}
      {isEditing && <EditDetails setIsEditing={setIsEditing} />}
    </>
  );
};

export default LoggedIn;
