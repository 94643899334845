// NewMail.jsx

import React, { useState, useRef, useEffect } from "react";
import {
  processSpecialCommands,
  updateFormFields,
} from "../scripts/speechUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faXmark,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { auth, db } from "../firebase-config";
import { getDoc, doc } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import SynonymAssistant from "./UMailContext/SynonymAssistant";
import SpellCheckAssistant from "./UMailContext/SpellCheckAssistant";
import LanguageAssistant from "./UMailContext/LanguageAssistant";
import TextRefinementAssistant from "./UMailContext/TextRefinementAssistant";
import EditOptions from "./UMailContext/NormalEditOptions";
import MoodToneAssistant from "./UMailContext/MoodToneAssistant";
import IndustryAssistant from "./UMailContext/IndustryAssistant";
import ZoomScheduler from "./UMailContext/ZoomScheduler";
import GoogleCalendarScheduler from "./UMailContext/GoogleCalendarScheduler";
import WritingStyleAssistant from "./UMailContext/WritingStyleAssistant";
import DictionaryAssistant from "./UMailContext/DictionaryAssistant";
import TemplateUpdateModal from "./UMailContext/TemplateUpdateModal";
import axios from 'axios'; // For sending email using Gmail API

const fieldKeywords = {
  to: ["email", "receiver"],
  subject: ["subject"],
  message: ["message"],
};

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

const NewMail = ({
  isListening,
  setPopupMessage,
  useSignature,
  setUseSignature,
  signature,
  setSignature,
  setMailContent,
  mailContent,
  userEmail,
  accessToken, // Pass accessToken from the parent component
  onEmailSent // Added prop for redirecting after sending email
}) => {
  const recognition = useRef(new SpeechRecognition());
  const [enhancedText, setEnhancedText] = useState("");
  const [enhanceCount, setEnhanceCount] = useState(0);
  const [lastSentText, setLastSentText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showMoodToneModal, setShowMoodToneModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showIndustryModal, setShowIndustryModal] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedMood, setSelectedMood] = useState("Neutral");
  const [selectedTone, setSelectedTone] = useState("Formal");
  const [showWritingStyleModal, setShowWritingStyleModal] = useState(false);
  const [resumeUrl, setResumeUrl] = useState("");
  const [user, setUser] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState("Coral"); // Default template
  const [showTemplateModal, setShowTemplateModal] = useState(false); // Modal state
  const [resumeGenerated, setResumeGenerated] = useState(false);

  // Ref for the message div
  const messageRef = useRef(null);

  // Ref for Toast
  const toastRef = useRef(null);

  recognition.current.continuous = true;
  recognition.current.interimResults = true;
  recognition.current.lang = "en-US";

  useEffect(() => {
    const handleResult = (event) => {
      let transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      const cleanTranscript = transcript;
      const words = cleanTranscript.split(" ");
      const processedTranscript = processSpecialCommands(words);

      const fieldsUpdate = updateFormFields(processedTranscript, fieldKeywords);
      setMailContent((prev) => ({
        ...prev,
        ...fieldsUpdate,
      }));
      setEnhancedText(processedTranscript);

      // Update the content of the message element directly
      if (messageRef.current) {
        messageRef.current.innerText = processedTranscript;
        adjustHeight();
      }
    };

    recognition.current.onresult = handleResult;

    if (isListening) {
      recognition.current.start();
    } else {
      recognition.current.stop();
    }

    return () => {
      recognition.current.stop();
    };
  }, [isListening, setMailContent]);

  // Removed the useEffect that updates the div from state changes

  const handleInputChange = (field, value) => {
    if (field === "from" || field === "to") {
      value = value.replace(/\s*@|\s*(?=@)/g, "@");
    }

    setMailContent((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const clearForm = () => {
    setMailContent({ id: null, from: "", to: "", subject: "", message: "" });
    if (messageRef.current) {
      messageRef.current.innerText = "";
      adjustHeight();
    }
  };

  const enhanceText = async (text) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/text-enhance`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            prompt: text,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setLoading(false);
      return data.text ? data.text.trim() : "No text generated.";
    } catch (error) {
      setLoading(false);
      console.error("Error enhancing text:", error);
      throw error; // Re-throw to handle it later
    }
  };

  const customEnhanceText = async (text, mood, tone) => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/custom-enhance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: text,
          mood: mood,
          tone: tone,
        }),
      }
    );

    if (!response.ok) {
      setLoading(false);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setLoading(false);
    console.log(data); // Log the response data for debugging
    return data.text ? data.text.trim() : "No text generated.";
  };

  const displayEnhancedText = async () => {
    const messageContent = mailContent.message; // Use mailContent.message instead of messageRef.current.innerText

    if (!messageContent.trim()) {
      setPopupMessageWithTimeout("Add some text please!");
      return;
    }

    try {
      const enhanced = await enhanceText(messageContent);
      const splitIndex = enhanced?.indexOf("\n");
      const enhancedSubject =
        splitIndex !== -1 ? enhanced.substring(0, splitIndex).trim() : enhanced;
      const enhancedMessage =
        splitIndex !== -1 ? enhanced.substring(splitIndex + 1).trim() : "";

      // Directly update the state and div without triggering useEffect
      setMailContent((prev) => ({
        ...prev,
        subject: enhancedSubject,
        message: `${enhancedMessage}`,
      }));
      if (messageRef.current) {
        messageRef.current.innerText = `Hello,\n\n${enhancedMessage}`;
        adjustHeight();
      }

      setLastSentText(messageContent);
      setEnhanceCount((prevCount) => prevCount + 1);
    } catch (error) {
      setMailContent((prev) => ({
        ...prev,
        message: "An error occurred while enhancing the text.",
      }));
      if (messageRef.current) {
        messageRef.current.innerText = "An error occurred while enhancing the text.";
        adjustHeight();
      }
      console.error("Error enhancing text:", error);
    }
  };

  const displayCustomEnhancedText = async () => {
    const messageContent = mailContent.message; // Use mailContent.message instead of messageRef.current.innerText

    if (!messageContent.trim()) {
      setPopupMessageWithTimeout("Add some text homie!");
      return;
    }

    try {
      const enhanced = await customEnhanceText(
        messageContent,
        selectedMood,
        selectedTone
      );
      const splitIndex = enhanced?.indexOf("\n");
      const enhancedSubject =
        splitIndex !== -1 ? enhanced.substring(0, splitIndex).trim() : enhanced;
      const enhancedMessage =
        splitIndex !== -1 ? enhanced.substring(splitIndex + 1).trim() : "";

      // Directly update the state and div without triggering useEffect
      setMailContent((prev) => ({
        ...prev,
        subject: enhancedSubject,
        message: `${enhancedMessage}`,
      }));
      if (messageRef.current) {
        messageRef.current.innerText = `${enhancedMessage}`;
        adjustHeight();
      }

      setLastSentText(messageContent);
    } catch (error) {
      setMailContent((prev) => ({
        ...prev,
        message: "An error occurred while enhancing the text.",
      }));
      if (messageRef.current) {
        messageRef.current.innerText = "An error occurred while enhancing the text.";
        adjustHeight();
      }
      console.error("Error enhancing text:", error);
    }
  };

  const setPopupMessageWithTimeout = (message) => {
    setPopupMessage(message);
    setTimeout(() => setPopupMessage(""), 4000);
  };

  const handleSignatureToggle = async () => {
    if (!auth.currentUser) {
      console.error("No authenticated user. Please log in.");
      setPopupMessageWithTimeout("No authenticated user. Please log in.");
      return;
    }

    const newUseSignature = !useSignature;
    setUseSignature(newUseSignature);

    if (newUseSignature) {
      try {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userSignature = userDoc.data().signature || "";
          setSignature(userSignature);

          // Directly update the state and div without triggering useEffect
          setMailContent((prev) => ({
            ...prev,
            message: `${prev.message}\n\n${userSignature}`,
          }));
          if (messageRef.current) {
            messageRef.current.innerText += `\n\n${userSignature}`;
            adjustHeight();
          }
        }
      } catch (error) {
        console.error("Error fetching user signature:", error);
      }
    } else {
      const messageContent = mailContent.message; // Use mailContent.message instead of messageRef.current.innerText
      const updatedMessage = messageContent.replace(`\n\n${signature}`, "");

      // Directly update the state and div without triggering useEffect
      setMailContent((prev) => ({
        ...prev,
        message: updatedMessage,
      }));
      if (messageRef.current) {
        messageRef.current.innerText = updatedMessage;
        adjustHeight();
      }
    }
  };

  const handleIndustryChange = async (industry) => {
    setSelectedIndustry(industry);
    setShowIndustryModal(false);

    const messageContent = mailContent.message; // Use mailContent.message instead of messageRef.current.innerText
    if (!messageContent.trim()) {
      setPopupMessageWithTimeout("Add some text to enhance!");
      return;
    }

    try {
      const enhanced = await enhanceIndustryText(messageContent, industry);
      const splitIndex = enhanced?.indexOf("\n");
      const enhancedSubject =
        splitIndex !== -1 ? enhanced.substring(0, splitIndex).trim() : enhanced;
      const enhancedMessage =
        splitIndex !== -1 ? enhanced.substring(splitIndex + 1).trim() : "";

      // Directly update the state and div without triggering useEffect
      setMailContent((prev) => ({
        ...prev,
        subject: enhancedSubject,
        message: enhancedMessage,
      }));
      document.getElementById("subject").value = enhancedSubject;
      if (messageRef.current) {
        messageRef.current.innerText = enhancedMessage;
        adjustHeight();
      }
    } catch (error) {
      console.error("Error enhancing text with industry lingo:", error);
    }
  };

  const enhanceIndustryText = async (text, industry) => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/industry-enhance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: text,
          industry: industry,
        }),
      }
    );

    if (!response.ok) {
      setLoading(false);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setLoading(false);
    console.log(data); // Log the response data for debugging
    return data.text ? data.text.trim() : "No text generated.";
  };

  // Function to adjust the height of the message div
  const adjustHeight = () => {
    const el = messageRef.current;
    if (el) {
      el.style.height = 'auto'; // Reset the height
      el.style.height = `${el.scrollHeight}px`; // Set it to the scrollHeight
    }
  };

  useEffect(() => {
    const messageElement = messageRef.current;
  
    const handleCopy = (event) => {
      if (event.target.id === "message") {
        event.preventDefault();
        setPopupMessageWithTimeout("You can't copy text from U-Mail.");
      }
    };
  
    if (messageElement) {
      messageElement.addEventListener("copy", handleCopy);
    }
  
    return () => {
      if (messageElement) {
        messageElement.removeEventListener("copy", handleCopy);
      }
    };
  }, [setPopupMessage]);

  const handleSendEmail = async () => {
    setLoading(true);
    
    try {
      const messageContent = document.getElementById('message').innerText; // Use mailContent.message instead of messageRef.current.innerText

      if (!messageContent.trim()) {
        setPopupMessageWithTimeout("Please enter a message before sending.");
        setLoading(false);
        return;
      }

      const response = await axios.post('https://umail-backend.onrender.com/api/send-email', {
        to: mailContent.to,
        subject: mailContent.subject,
        message: messageContent, // Use the content from mailContent.message
        accessToken: accessToken,  // Use the passed OAuth2 access token instead of localStorage
        userEmail: userEmail       // Use the passed logged-in user's email
      });

      if (response.data.success) {
        showToast('Email sent successfully!');
        setPopupMessage(''); // Clear any existing popup message
        clearForm(); // Clear the form after sending
        onEmailSent(); // Redirect to inbox after sending email
      } else {
        showToast('Failed to send email.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      showToast('Failed to send email.');
    }
    
    setLoading(false);
  };

  const showToast = (message) => {
    const toastElement = toastRef.current;
    if (toastElement) {
      toastElement.querySelector('.toast-body').innerText = message;
      const toast = new window.bootstrap.Toast(toastElement);
      toast.show();
    }
  };

  return (
    <div className="gmail-content">
      {/* Header Section */}
      <div className="gmail-content-header">
        <h2>Compose Email</h2>
        <div className="action-buttons">
          <button className="btn btn-primary" onClick={handleSendEmail} disabled={loading}>
            <FontAwesomeIcon icon={faPaperPlane} /> Send
          </button>
          <button className="btn btn-secondary" onClick={clearForm}>
            <FontAwesomeIcon icon={faXmark} /> Clear
          </button>
        </div>
      </div>

      {/* Form Fields */}
      <form className="row">
        {/* From Field */}
        <div className="form-field col-12 col-md-6">
          <input
            id="from"
            className="form-control"
            placeholder="From"
            type="text"
            value={userEmail}
            readOnly
            required
          />
        </div>
        
        {/* To Field */}
        <div className="form-field col-12 col-md-6">
          <input
            id="email"
            className="form-control"
            placeholder="Receiver Email"
            type="email"
            value={mailContent.to}
            onChange={(e) => handleInputChange("to", e.target.value)}
            required
          />
        </div>
        
        {/* Subject Field */}
        <div className="form-field col-12">
          <input
            id="subject"
            className="form-control"
            placeholder="Subject"
            type="text"
            value={mailContent.subject}
            onChange={(e) => handleInputChange("subject", e.target.value)}
            required
          />
        </div>
        
        {/* Edit Options */}
        <EditOptions
          handleSignatureToggle={handleSignatureToggle}
          useSignature={useSignature}
          setMailContent={setMailContent}
        />

        {/* AI Enhancement Accordion */}
        <div className="accordion mb-3" id="enhanceAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                AI Enhancement Options
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#enhanceAccordion"
            >
              <div className="accordion-body">
                <div className="d-flex flex-wrap align-items-center mb-3 gap-2">
                  {/* Enhance with AI Button */}
                  <button
                    className="btn btn-primary btn-sm get-in-touch"
                    type="button"
                    onClick={() => setShowMoodToneModal(true)}
                  >
                    Enhance with AI
                  </button>
                  
                  {/* Language Assistant */}
                  <LanguageAssistant
                    setPopupMessageWithTimeout={setPopupMessageWithTimeout}
                    mailContent={mailContent}
                    setMailContent={setMailContent}
                  />
                  
                  {/* Industry Specific Lingo Button */}
                  <button
                    className="btn btn-primary btn-sm get-in-touch"
                    type="button"
                    onClick={() => setShowIndustryModal(true)}
                  >
                    Industry Specific Lingo
                  </button>
                  
                  {/* Writing Styles Button */}
                  <button
                    className="btn btn-primary btn-sm get-in-touch"
                    onClick={() => setShowWritingStyleModal(true)}
                  >
                    Writing Styles
                  </button>
                  
                  {/* Text Refinement Assistant */}
                  <TextRefinementAssistant
                    setPopupMessageWithTimeout={setPopupMessageWithTimeout}
                  />
                  
                  {/* Synonym Assistant */}
                  <SynonymAssistant
                    setPopupMessageWithTimeout={setPopupMessageWithTimeout}
                    mailContent={mailContent}
                    setMailContent={setMailContent}
                  />
                  
                  {/* Spell Check Assistant */}
                  <SpellCheckAssistant
                    setPopupMessageWithTimeout={setPopupMessageWithTimeout}
                  />
                  
                  {/* Dictionary Assistant */}
                  <DictionaryAssistant
                    setPopupMessageWithTimeout={setPopupMessageWithTimeout}
                    mailContent={mailContent}
                    setMailContent={setMailContent}
                  />
                  
                  {/* See Before Text Button */}
                  <button
                    className="btn btn-outline-primary btn-sm"
                    type="button"
                    onClick={() => setShowModal(true)}
                  >
                    See Before Text
                  </button>
                  
                  {/* Template Update Modal */}
                  {resumeGenerated && (
                    <TemplateUpdateModal
                      showTemplateModal={showTemplateModal}
                      setShowTemplateModal={setShowTemplateModal}
                      selectedTemplate={selectedTemplate}
                      setSelectedTemplate={setSelectedTemplate}
                      loading={loading}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Message Box */}
        <div
          id="message"
          ref={messageRef}
          className="input-text form-control empty"
          contentEditable="true"
          onInput={(e) => {
            adjustHeight();
            handleInputChange('message', e.currentTarget.innerText); // Update mailContent.message on input
          }}
          aria-label="Email Message"
        ></div>
      </form>

      {/* Action Buttons */}
      <div className="btn-group">
        <div className="d-flex flex-wrap align-items-center gap-2">
          {/* Zoom Scheduler */}
          <ZoomScheduler
            setPopupMessageWithTimeout={setPopupMessageWithTimeout}
            mailContent={mailContent}
            setMailContent={setMailContent}
            userEmail={userEmail}
          />
          
          {/* Google Calendar Scheduler */}
          <GoogleCalendarScheduler
            setPopupMessageWithTimeout={setPopupMessageWithTimeout}
            mailContent={mailContent}
            setMailContent={setMailContent}
            userEmail={userEmail}
          />
          
          {/* Send Email Button */}
          <button
            onClick={handleSendEmail}
            className="btn btn-primary"
            disabled={loading}
          >
            <FontAwesomeIcon icon={faPaperPlane} /> Send
          </button>
          
          {/* Save Email Button */}
          <button id="save" className="btn btn-warning" type="button">
            <FontAwesomeIcon icon={faArrowDown} /> Save
          </button>
          
          {/* Clear Form Button */}
          <button
            id="clear"
            className="btn btn-secondary"
            type="button"
            onClick={clearForm}
          >
            <FontAwesomeIcon icon={faXmark} /> Clear
          </button>
        </div>
      </div>

      {/* Modal for Before Text */}
      <div
        className={`modal fade ${showModal ? "show d-block" : "fade"}`}
        tabIndex="-1"
        role="dialog"
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Text Sent to U-Mail AI Enhancer</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setShowModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <pre>{lastSentText}</pre>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Mood and Tone */}
      {showMoodToneModal && (
        <MoodToneAssistant
          setSelectedMood={setSelectedMood}
          setSelectedTone={setSelectedTone}
          displayCustomEnhancedText={displayCustomEnhancedText}
          setShowMoodToneModal={setShowMoodToneModal}
          displayEnhancedText={displayEnhancedText}
        />
      )}

      {/* Modal for Writing Style Assistant */}
      {showWritingStyleModal && (
        <WritingStyleAssistant
          setPopupMessageWithTimeout={setPopupMessageWithTimeout}
          setShowWritingStyleModal={setShowWritingStyleModal}
        />
      )}

      {/* Modal for Industry Selection */}
      <IndustryAssistant
        handleIndustryChange={handleIndustryChange}
        showIndustryModal={showIndustryModal}
        setShowIndustryModal={setShowIndustryModal}
        loading={loading}
      />

      {/* Toast Notification */}
      <div
        className="toast-container position-fixed bottom-0 end-0 p-3"
        style={{ zIndex: 11 }}
      >
        <div className="toast" role="alert" aria-live="assertive" aria-atomic="true" ref={toastRef}>
          <div className="toast-header">
            <strong className="me-auto">U-Mail</strong>
            <small>Just now</small>
            <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div className="toast-body">
            Email sent successfully!
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMail;
