// axiosInstance.js or wherever you configure axios
import axios from 'axios';

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: 'https://umail-backend.onrender.com',
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const { config, response } = error;
    if (response && response.status === 429) {
      // Check if we've already retried the request
      config.__retryCount = config.__retryCount || 0;
      if (config.__retryCount >= 5) {
        // Reject with the error if we've retried 5 times
        return Promise.reject(error);
      }
      config.__retryCount += 1;
      // Exponential backoff delay
      const delay = Math.pow(2, config.__retryCount) * 1000;
      await new Promise(res => setTimeout(res, delay));
      return axiosInstance(config);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
