import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SentMails = ({ accessToken, handleMailClick, showMailPopup, selectedMail, handleClosePopup }) => {
  const [sentEmails, setSentEmails] = useState([]);

  useEffect(() => {
    if (accessToken) {
      fetchSentEmails();
    }
  }, [accessToken]);

  // Fetch sent emails from Gmail API
  const fetchSentEmails = async () => {
    try {
      const response = await axios.get('https://www.googleapis.com/gmail/v1/users/me/messages', {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: {
          q: 'in:sent', // Query for sent emails
          maxResults: 20 // Limit the number of results (change as needed)
        }
      });

      const messages = response.data.messages || [];
      if (messages.length > 0) {
        const detailedMessages = await Promise.all(
          messages.map(async (message) => {
            const messageDetails = await axios.get(
              `https://www.googleapis.com/gmail/v1/users/me/messages/${message.id}`,
              { 
                headers: { Authorization: `Bearer ${accessToken}` },
                params: { format: 'full' } // Get full email details
              }
            );
            return messageDetails.data;
          })
        );

        setSentEmails(detailedMessages);
      }
    } catch (error) {
      console.error('Error fetching sent emails:', error);
    }
  };

  // Function to get the message snippet or body
  const getMessageSnippet = (mail) => {
    return mail.snippet;
  };

  // Function to get the message body
  const getMessageBody = (messagePayload) => {
    let encodedBody = '';

    if (!messagePayload.parts) {
      encodedBody = messagePayload.body.data;
    } else {
      encodedBody = getHTMLPart(messagePayload.parts);
    }

    if (encodedBody) {
      const decodedBody = atob(encodedBody.replace(/-/g, '+').replace(/_/g, '/'));
      return decodedBody;
    } else {
      return '';
    }
  };

  const getHTMLPart = (parts) => {
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].mimeType === 'text/html') {
        return parts[i].body.data;
      } else if (parts[i].parts) {
        const htmlPart = getHTMLPart(parts[i].parts);
        if (htmlPart) {
          return htmlPart;
        }
      }
    }
    return null;
  };

  return (
    <div className="container mt-5">
      <h1>Sent U-Mails</h1>
      <div className="email-list">
        {sentEmails.map((mail, index) => {
          const toHeader = mail.payload.headers.find(header => header.name === 'To')?.value || 'Unknown';
          const subjectHeader = mail.payload.headers.find(header => header.name === 'Subject')?.value || 'No Subject';
          const dateHeader = mail.payload.headers.find(header => header.name === 'Date')?.value || 'Unknown Date';
          const snippet = getMessageSnippet(mail);

          return (
            <div key={mail.id || index} className="email-item" onClick={() => handleMailClick(mail)}>
              <div className="email-checkbox">
                <input type="checkbox" />
              </div>
              <div className="email-recipient">
                {toHeader}
              </div>
              <div className="email-subject">
                <strong>{subjectHeader}</strong> - {snippet}
              </div>
              <div className="email-date">
                {new Date(dateHeader).toLocaleString()}
              </div>
            </div>
          );
        })}
      </div>

      {showMailPopup && selectedMail && (
        <div className="popup">
          <div className="popup-inner">
            <button className="btn btn-dark" onClick={handleClosePopup}>Close</button>
            <h3>{selectedMail.payload.headers.find(header => header.name === 'Subject')?.value}</h3>
            <p>To: {selectedMail.payload.headers.find(header => header.name === 'To')?.value}</p>
            <p>Date: {new Date(selectedMail.payload.headers.find(header => header.name === 'Date')?.value).toLocaleString()}</p>
            <hr />
            <div className="email-body" dangerouslySetInnerHTML={{ __html: getMessageBody(selectedMail.payload) }}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SentMails;
